import Api from '@/services/Api'

export default {
  findAll (pagination) {
    return Api().post('users', pagination)
  },
  insert (user) {
    return Api().post('user', user)
  },
  update (user) {
    return Api().put('user', user)
  },
  delete (user) {
    return Api().delete(`user?id=${user.id}`, user)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('usersParam', param)
  },
  findById (user) {
    const url = `user/${user.id}`
    return Api().get(url)
  }
}
