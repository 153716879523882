<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :items="items"
    :total-registros="totalRegistros"
    :paginacao.sync="pagination"
    :hide-headers="$vuetify.breakpoint.smAndDown"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template
      slot="items"
      slot-scope="{ item }"
    >
      <tr>
        <td
          class="justify-left btnEditar cursorPointer"
          @click="editar(item.item)"
        >
          {{ item.item.nome }}
          <v-icon small>
            mdi-pencil
          </v-icon>
        </td>
        <td>
          {{ item.item.email }}
        </td>
        <td>
          {{ item.item.habilitado ? $t('padrao_sim') : $t('padrao_nao') }}
        </td>
      </tr>
    </template>
  </crud-list>
</template>

<script>

  import UserService from '@/services/UserService'

  export default {
    data: () => ({
      totalRegistros: 0,
      pagination: {},
      items: [],
      loading: false,
      itemRemover: {}
    }),
    computed: {
      tituloPesquisa: function () {
        return this.$i18n.t('UserForm.tituloPesquisa')
      },
      headers: function () {
        return [
          {
            text: this.$i18n.t('UserForm.nome'),
            value: 'nome'
          },
          {
            text: this.$i18n.t('UserForm.email'),
            value: 'email',
            sortable: false
          },
          {
            text: this.$i18n.t('UserForm.habilitado'),
            value: 'habilitado',
            sortable: false
          }
        ]
      }
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', true)
      this.$root._events.pesquisa = []
      this.$root.$on('pesquisa', this.efetuarPesquisa)
    },
    methods: {

      efetuarPesquisa (pesquisa) {
        this.loading = true
        const param = {
          nome: pesquisa
        }
        UserService.findByParam(param, this.pagination)
          .then((retorno) => {
            if (retorno && retorno.data && retorno.data.rows) {
              this.items = retorno.data.rows
            }
            if (retorno && retorno.data) {
              this.totalRegistros = retorno.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro ao buscar os tipos de evento cadastrados: \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      buscar () {
        this.loading = true
        UserService.findAll(this.pagination)
          .then((retorno) => {
            if (retorno && retorno.data && retorno.data.rows) {
              this.items = retorno.data.rows
            }
            if (retorno && retorno.data) {
              this.totalRegistros = retorno.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro ao buscar os tipos de evento cadastrados: \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      novo () {
        this.$router.push({
          path: '/userForm'
        })
      },
      editar (user) {
        if (user && user.id) {
          const url = '/userForm/' + user.id
          this.$router.push(url)
        }
      }
    }
  }
</script>

<style>
</style>
